<template>
  <header
    id="site-header"
    class="fixed-top header-scrolled-mob header-scrolled"
   
  >
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light">
        <router-link class="navbar-brand" to="/"
          ><img
            src="@/assets/site/images/accenta-logo.png"
            class="img-fluid"
            alt=""
          />
        </router-link>
        <Slide right class="mobile-menu d-blcok d-sm-none">
          <router-link class="nav-link active" aria-current="page" to="/"
            >Home</router-link
          >
          <router-link class="nav-link" to="/about">About Us</router-link>
          <li class="nav-link">
            <ul class="menu">
              <li class="menu-item">
                <a href="#">Courses</a>
                <ul class="drop-menu">
                  <li class="drop-menu-item">
                    <router-link to="/ielts">IELTS</router-link>
                  </li>
                  <li class="drop-menu-item">
                    <router-link to="/kids-english">Kids English</router-link>
                  </li>
                  <li class="drop-menu-item">
                    <router-link to="/spoken-english"
                      >Spoken English</router-link
                    >
                  </li>
                  <li class="drop-menu-item">
                    <router-link to="/interview-english"
                      >Interview English</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="nav-link">
            <ul class="menu">
              <li class="menu-item">
                <a href="#">Services</a>
                <ul class="drop-menu">
                  <li class="drop-menu-item">
                    <router-link to="/schools">Schools</router-link>
                  </li>
                  <li class="drop-menu-item">
                    <router-link to="/colleges">Colleges</router-link>
                  </li>
                  <li class="drop-menu-item">
                    <router-link to="/corporates">Corporates</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <router-link class="nav-link" to="/admissions"
            >Admissions</router-link
          >
          <router-link class="nav-link" to="/gallery">Gallery</router-link>
          <router-link class="nav-link" to="/contact">Contact</router-link>
        </Slide>
        <div
          class="collapse navbar-collapse d-none d-sm-block"
          id="navbarScroll"
        >
          <ul class="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
            <li class="nav-item">
              <router-link class="nav-link active" aria-current="page" to="/"
                >Home</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/about">About Us</router-link>
            </li>
            <li class="nav-item">
              <ul class="menu">
                <li class="menu-item">
                  <a href="#">Courses</a>
                  <ul class="drop-menu">
                    <li class="drop-menu-item">
                      <router-link to="/ielts">IELTS</router-link>
                    </li>
                    <li class="drop-menu-item">
                      <router-link to="/kids-english">Kids English</router-link>
                    </li>
                    <li class="drop-menu-item">
                      <router-link to="/spoken-english"
                        >Spoken English</router-link
                      >
                    </li>
                    <li class="drop-menu-item">
                      <router-link to="/interview-english"
                        >Interview English</router-link
                      >
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <ul class="menu">
                <li class="menu-item">
                  <a href="#">Services</a>
                  <ul class="drop-menu">
                    <li class="drop-menu-item">
                      <router-link to="/schools">Schools</router-link>
                    </li>
                    <li class="drop-menu-item">
                      <router-link to="/colleges">Colleges</router-link>
                    </li>
                    <li class="drop-menu-item">
                      <router-link to="/corporates">Corporates</router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/admissions"
                >Admissions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/gallery">Gallery</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contact">Contact</router-link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>

import { Slide } from "vue-burger-menu"; // import the CSS transitions you wish to use, in this case we are using `Slide`
export default {
  name: "HomeBar",
  components: {
    Slide, // Register your component
  },
 
};
</script>

<style scoped>
.router-link-active {
  color: rgba(10, 10, 10, 1) !important;
  /* border-bottom: 2px solid var(--primary); */
  display: block !important;
}

.no-active {
  color: var(--dark) !important;
  border-bottom: 2px solid transparent;
  display: block !important;
}

.dropdown-toggle::after {
  display: none;
}

/* Please ❤ this if you like it! */

/* #Primary
================================================== */

::selection {
  color: #fff;
  background-color: #8167a9;
}
::-moz-selection {
  color: #fff;
  background-color: #8167a9;
}

/* #Navigation
================================================== */

.start-header {
  opacity: 1;
  transform: translateY(0);
  padding: 20px 0;
  box-shadow: 0 10px 30px 0 rgba(138, 155, 165, 0.15);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  padding: 10px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.start-header.scroll-on .navbar-brand img {
  height: 24px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navigation-wrap {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar {
  padding: 0;
}
.navbar-brand img {
  height: 60px;
  width: auto;
  display: block;
  /* filter: brightness(10%); */
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.navbar-toggler {
  float: right;
  border: none;
  padding-right: 0;
}
.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none;
}
.navbar-light .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 1px solid #fff;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after,
.navbar-light .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 1px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-light .navbar-toggler-icon:after {
  top: 8px;
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
}
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
.nav-link {
  /* color: #fff !important; */
  font-weight: 500;
  transition: all 200ms linear;
  font-size: 32px;
  align-content: center;
}
.nav-item:hover .nav-link {
  color: #8167a9 !important;
}
.nav-item.active .nav-link {
  color: #777 !important;
}
.nav-link {
  position: relative;
  padding: 5px 0 !important;
  display: inline-block;
}
/* .nav-item:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #8167a9;
  opacity: 0;
  transition: all 200ms linear;
}
.nav-item:hover:after {
  bottom: 0;
  opacity: 1;
} */
.nav-item.active:hover:after {
  opacity: 0;
}
.nav-item {
  /* position: relative; */
  transition: all 200ms linear;
}

/* #Primary style
================================================== */

.bg-light {
  background-color: #fff !important;
  transition: all 200ms linear;
}
.section {
  position: relative;
  width: 100%;
  display: block;
}
.full-height {
  height: 100vh;
}
.over-hide {
  overflow: hidden;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  margin-top: 40px;
  transform: translateY(-50%);
  z-index: 20;
}

.hero-anime p {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1700ms;
}
h1 span {
  display: inline-block;
  transition: all 300ms linear;
  opacity: 1;
  transform: translate(0);
}
.hero-anime h1 span:nth-child(1) {
  opacity: 0;
  transform: translateY(-20px);
}
.hero-anime h1 span:nth-child(2) {
  opacity: 0;
  transform: translateY(-30px);
}
.hero-anime h1 span:nth-child(3) {
  opacity: 0;
  transform: translateY(-50px);
}
.hero-anime h1 span:nth-child(4) {
  opacity: 0;
  transform: translateY(-10px);
}
.hero-anime h1 span:nth-child(5) {
  opacity: 0;
  transform: translateY(-50px);
}
.hero-anime h1 span:nth-child(6) {
  opacity: 0;
  transform: translateY(-20px);
}
.hero-anime h1 span:nth-child(7) {
  opacity: 0;
  transform: translateY(-40px);
}
.hero-anime h1 span:nth-child(8) {
  opacity: 0;
  transform: translateY(-10px);
}
.hero-anime h1 span:nth-child(9) {
  opacity: 0;
  transform: translateY(-30px);
}
.hero-anime h1 span:nth-child(10) {
  opacity: 0;
  transform: translateY(-20px);
}
h1 span:nth-child(1) {
  transition-delay: 1000ms;
}
h1 span:nth-child(2) {
  transition-delay: 700ms;
}
h1 span:nth-child(3) {
  transition-delay: 900ms;
}
h1 span:nth-child(4) {
  transition-delay: 800ms;
}
h1 span:nth-child(5) {
  transition-delay: 1000ms;
}
h1 span:nth-child(6) {
  transition-delay: 700ms;
}
h1 span:nth-child(7) {
  transition-delay: 900ms;
}
h1 span:nth-child(8) {
  transition-delay: 800ms;
}
h1 span:nth-child(9) {
  transition-delay: 600ms;
}
h1 span:nth-child(10) {
  transition-delay: 700ms;
}
.hero-anime h1 span:nth-child(11) {
  opacity: 0;
  transform: translateY(30px);
}
.hero-anime h1 span:nth-child(12) {
  opacity: 0;
  transform: translateY(50px);
}
.hero-anime h1 span:nth-child(13) {
  opacity: 0;
  transform: translateY(20px);
}
.hero-anime h1 span:nth-child(14) {
  opacity: 0;
  transform: translateY(30px);
}
.hero-anime h1 span:nth-child(15) {
  opacity: 0;
  transform: translateY(50px);
}
h1 span:nth-child(11) {
  transition-delay: 1300ms;
}
h1 span:nth-child(12) {
  transition-delay: 1500ms;
}
h1 span:nth-child(13) {
  transition-delay: 1400ms;
}
h1 span:nth-child(14) {
  transition-delay: 1200ms;
}
h1 span:nth-child(15) {
  transition-delay: 1450ms;
}
#switch,
#circle {
  cursor: pointer;
  -webkit-transition: all 300ms linear;
  transition: all 300ms linear;
}
#switch {
  width: 60px;
  height: 8px;
  border: 2px solid #8167a9;
  border-radius: 27px;
  background: #000;
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
  opacity: 1;
  transform: translate(0);
  transition: all 300ms linear;
  transition-delay: 1900ms;
}
.hero-anime #switch {
  opacity: 0;
  transform: translateY(40px);
  transition-delay: 1900ms;
}
#circle {
  position: absolute;
  top: -11px;
  left: -13px;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #000;
}
.switched {
  border-color: #000 !important;
  background: #8167a9 !important;
}
.switched #circle {
  left: 43px;
  box-shadow: 0 4px 4px rgba(26, 53, 71, 0.25), 0 0 0 1px rgba(26, 53, 71, 0.07);
  background: #fff;
}
.nav-item .dropdown-menu {
  transform: translate3d(0, 10px, 0);
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  display: block;
  padding: 0;
  margin: 0;
  transition: all 200ms linear;
}
.nav-item.show .dropdown-menu {
  opacity: 1;
  visibility: visible;
  max-height: 999px;
  transform: translate3d(0, 0px, 0);
}
.dropdown-menu {
  padding: 10px !important;
  margin: 0;
  font-size: 13px;
  letter-spacing: 1px;
  color: #212121;
  background-color: #fcfaff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 5px 10px 0 rgba(138, 155, 165, 0.15);
  transition: all 200ms linear;
}
.dropdown-toggle::after {
  display: none;
}

.dropdown-item {
  padding: 3px 15px;
  color: #212121;
  border-radius: 2px;
  transition: all 200ms linear;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #fff;
  background-color: rgba(129, 103, 169, 0.6);
}

.dark {
  color: #fff;
  background-color: #1f2029;
}
.dark .navbar-brand img {
  filter: brightness(100%);
}
.dark h1 {
  color: #fff;
}
.dark h1 span {
  transition-delay: 0ms !important;
}
.dark p {
  color: #fff;
  transition-delay: 0ms !important;
}
.dark .bg-light {
  background-color: #14151a !important;
}
.dark .start-header {
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
}
.dark .start-header.scroll-on {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
}
.dark .nav-link {
  color: #fff !important;
}
.dark .nav-item.active .nav-link {
  color: #999 !important;
}
.dark .dropdown-menu {
  color: #fff;
  background-color: #1f2029;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
}
.dark .dropdown-item {
  color: #fff;
}
.dark .navbar-light .navbar-toggler-icon {
  border-bottom: 1px solid #fff;
}
.dark .navbar-light .navbar-toggler-icon:after,
.dark .navbar-light .navbar-toggler-icon:before {
  background-color: #fff;
}
.dark .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/* #Media
================================================== */

@media (max-width: 767px) {
  h1 {
    font-size: 38px;
  }
  .nav-item:after {
    display: none;
  }
  .nav-item::before {
    position: absolute;
    display: block;
    top: 15px;
    left: 0;
    width: 11px;
    height: 1px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
  }
  .dropdown-toggle::after {
    position: absolute;
    display: block;
    top: 10px;
    left: -23px;
    width: 1px;
    height: 11px;
    content: "";
    border: none;
    background-color: #000;
    vertical-align: 0;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(90deg);
    opacity: 0;
  }
  .dropdown-menu {
    padding: 0 !important;
    background-color: transparent;
    box-shadow: none;
    transition: all 200ms linear;
  }
  .dropdown-toggle[aria-expanded="true"] + .dropdown-menu {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
  .dark .nav-item::before {
    background-color: #fff;
  }
  .dark .dropdown-toggle::after {
    background-color: #fff;
  }
  .dark .dropdown-menu {
    background-color: transparent;
    box-shadow: none;
  }
}

/* #Link to page
================================================== */
.bm-burger-button {
  position: fixed;
  width: 30px !important;
  height: 30px !important;
  left: 36px;
  top: 15px !important;
  cursor: pointer !important;
}
/* .bm-burger-bars {
  background-color: var(--techtaliya-blue) !important;
} */
.line-style {
  position: absolute;
  height: 8% !important;
  left: 0;
  right: 0;
  cursor: pointer !important;
}
.cross-style {
  position: absolute;
  top: 12px;
  right: 2px;
  cursor: pointer;
}
.bm-cross {
  background: #bdc3c7;
}
.bm-cross-button {
  height: 24px;
  width: 24px;
}
.bm-menu {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1000; /* Stay on top */
  top: 0;
  left: 0;
  background: #fff !important;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
.bm-item-list {
  color: #b8b7ad;
  margin-left: 10%;
  font-size: 20px;
}
.bm-item-list > * {
  display: flex;
  text-decoration: none;
  padding: 0.7em;
}
.bm-item-list > * > span {
  margin-left: 10px;
  font-weight: 700;
  color: var(--techtaliya-black) !important;
}
/* .nav-link{
  font-size: 20px !important;
} */
@media screen and (min-width: 576px) and (max-width: 768px) {
  #mobnav-img {
    display: none;
  }
  .nav-link {
    font-size: 18px !important;
  }
}
</style>
