<template>
  <!-- footer block -->

  <footer class="w3l-footer-29-main">
    <a
      href="//api.whatsapp.com/send?phone=917034553111&text=Hello"
      class="float"
      target="_blank"
    >
      <i class="bx bxl-whatsapp my-float"></i>
    </a>
    <div class="footer-29 pt-5 pb-4">
      <div class="container pt-md-4">
        <div class="row footer-top-29">
          <div class="col-lg-4 col-md-6 footer-list-29">
            <img
              src="@/assets/site/images/logo-white.png"
              class="img-fluid"
              alt=""
            />
            <p class="mb-2 pe-xl-5 pt-3">
              Accenta Education, 1 st Floor, Swarnam Arcade, Anamala Junction,
              N. Chalakudy, Thrissur, Kerala.
            </p>
            <p class="mb-2">
              Phone Number : <a href="tel:7034553111">+91 7034553111</a>,
              <a href="tel:7034513111">+91 7034513111</a>
            </p>
            <p class="mb-2">
              Email : <a href="mailto:info@accenta.in">info@accenta.in</a>
            </p>
            <!-- <img src="@/assets/site/images/iso.png" class=" iso-image"  alt=""> -->
          </div>
          <div class="col-lg-2 col-md-3 col-6 footer-list-29 mt-md-0 mt-4">
            <ul>
              <h6 class="footer-title-29">Quick Links</h6>
              <li><router-link to="/about">About Us</router-link></li>
              <li><router-link to="/ielts">Courses</router-link></li>
              <li><router-link to="/schools">Services</router-link></li>
              <li><router-link to="/contact">Contact Us</router-link></li>
              <li><router-link to="/careers">Careers</router-link></li>
            </ul>
          </div>
          <div
            class="col-lg-2 col-md-3 col-6 ps-lg-5 ps-lg-4 footer-list-29 mt-md-0 mt-4"
          >
            <ul>
              <h6 class="footer-title-29">Explore</h6>
              <li>
                <a href="https://youtube.com/@AccentaEducation" target="_blank"
                  >Youtube</a
                >
              </li>
              <li>
                <router-link to="/privacy-policy">Privacy policy</router-link>
              </li>
              <li><router-link to="/admissions">Admissions</router-link></li>
              <li>
                <a
                  href="https://www.facebook.com/accenta?mibextid=ZbWKwL"
                  target="_blank"
                  >Latest News</a
                >
              </li>
              <li><a href="https://www.accentaielts.in/" target="_blank">IELTS Blog</a></li>
            </ul>
          </div>
          <div
            class="col-lg-4 col-md-6 col-sm-8 footer-list-29 mt-lg-0 mt-4 ps-lg-5 d-none d-sm-block"
          >
            <h6 class="footer-title-29">Location</h6>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15701.555546559004!2d76.3371003!3d10.3107296!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080241947d9153%3A0x1e74db030c989632!2sAccenta%20Education!5e0!3m2!1sen!2sin!4v1678467387780!5m2!1sen!2sin"
              width="350"
              height="250"
              style="border: 10px"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div class="rounded-social-buttons pt-4">
          <a
            class="social-button facebook"
            href="https://www.facebook.com/accenta?mibextid=ZbWKwL"
            target="_blank"
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            class="social-button twitter"
            href="https://twitter.com/accentaedu?t=xrhxmLpddJerETx_ljNbpA&s=08"
            target="_blank"
            ><i class="fab fa-twitter"></i
          ></a>
          <a
            class="social-button linkedin"
            href="https://www.linkedin.com/in/accenta-education-3856b5263"
            target="_blank"
            ><i class="fab fa-linkedin"></i
          ></a>
          <a
            class="social-button youtube"
            href="https://youtube.com/@AccentaEducation"
            target="_blank"
            ><i class="fab fa-youtube"></i
          ></a>
          <a
            class="social-button instagram"
            href="https://instagram.com/accenta_education?igshid=ZDdkNTZiNTM="
            target="_blank"
            ><i class="fab fa-instagram"></i
          ></a>
        </div>
        <!-- copyright -->
        <p class="copy-footer-29 text-center pt-lg-2 mt-5 pb-2">
          © 2023 Accenta Education. All Rights Reserved. 
          <!-- Developed by -->
          <!-- <a href="https://www.oracloud.in/" target="_blank"
            >Oracloud Technologies</a
          > -->
        </p>
        <!-- //copyright -->
      </div>
    </div>
  </footer>
  <!-- //footer block -->
</template>
<script>
export default {
  name: "FooterBar",
};
</script>
<style scoped>
.router-link-active {
  color: #fbb034 !important;
  border-bottom: 2px solid var(--primary);
  display: inline-block !important;
}
</style>
