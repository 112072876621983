<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "@/assets/site/css/bootstrap.min.css";
import "@/assets/site/css/style-starter.css";
import "@/assets/site/css/boxicons/css/boxicons.css";
export default {
  name: "App",
};
</script>
