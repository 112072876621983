<template>
  <div id="layout">
    <main style="overflow: hidden">
      <menu-bar></menu-bar>
      <app-content />
      <footer-bar></footer-bar>
    </main>
  </div>
</template>

<script>
// Layout components
import MenuBar from "@/views/site/blocks/MenuBar";
import FooterBar from "@/views/site/blocks/FooterBar";
import AppContent from "@/views/site/blocks/AppContent";

export default {
  name: "SiteLayout",
  components: {
    MenuBar,
    FooterBar,
    AppContent,
  },
  watch: {
    $route(to, from) {
      let split_route = to.path.split("/");
      this.route_name = split_route[1];
    },
  },
};
// Layout components
</script>
