import Vue from "vue";
import VueRouter from "vue-router";

import SiteLayout from "@/views/layout/SiteLayout";
// Site Views ==> Lazy lodading components
const HomeView = () => import("@/views/site/Home/HomeView");
const AboutView = () => import("@/views/site/AboutView");
const Gallery = () => import("@/views/site/gallery/GalleryView");
const IeltsView = () => import("@/views/site/courses/IeltsView");
const KidsEnglish = () => import("@/views/site/courses/KidsEnglish");
const SpokenEnglish = () => import("@/views/site/courses/SpokenEnglish");
const InterviewEnglish = () => import("@/views/site/courses/InterviewEnglish");
const ContactView = () => import("@/views/site/ContactView");
const Redirect = () => import("@/views/site/Home/HomeView");
const PrivacyPolicy = () => import("@/views/site/PrivacyPolicy");
const AdmissionsView = () => import("@/views/site/AdmissionsView");
const Schools = () => import("@/views/site/services/Schools");
const Colleges = () => import("@/views/site/services/Colleges");
const Corporates = () => import("@/views/site/services/Corporates");
const Careers = () => import("@/views/site/CareersView");

Vue.use(VueRouter);

var routes;
var siteRoutesArray = [
  {
    path: "/",
    component: HomeView,
    name: "Home",
    meta: {
      title:
        "Accenta Education - IELTS, UKVI & Communicative English - Chalakudy",
    },
  },
  {
    path: "about",
    component: AboutView,
    name: "About",
    meta: { title: "Accenta Education - About Us - Meet Our Team" },
  },
  {
    path: "gallery",
    component: Gallery,
    name: "Gallery",
    meta: { title: "Accenta Education - Gallery - Explore More" },
  },
  {
    path: "ielts",
    component: IeltsView,
    name: "Ielts",
    meta: { title: "Accenta Education - IELTS Programs - Crash, Extensive & Online" },
  },
  {
    path: "kids-english",
    component: KidsEnglish,
    name: "KidsEnglish",
    meta: { title: "Accenta Education - Kids English - Online & Offline" },
  },
  {
    path: "spoken-english",
    component: SpokenEnglish,
    name: "SpokenEnglish",
    meta: { title: "Accenta Education - Spoken English Programs - Online & Offline" },
  },
  {
    path: "interview-english",
    component: InterviewEnglish,
    name: "InterviewEnglish",
    meta: { title: "Accenta Education - Interview English Training - Online & Offline" },
  },
  {
    path: "contact",
    component: ContactView,
    name: "Contact",
    meta: { title: "Accenta Education - Contact Us - Chalakudy" },
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicy,
    name: "Privacy",
    meta: { title: "Privacy Policy" },
  },
  {
    path: "admissions",
    component: AdmissionsView,
    name: "Admissions",
    meta: { title: "Accenta Education - Admissions - MBBS, Nursing, Abroad Education & more" },
  },
  {
    path: "schools",
    component: Schools,
    name: "Schools",
    meta: { title: "Accenta Education - School Programs - Communicative English & more" },
  },
  {
    path: "colleges",
    component: Colleges,
    name: "Colleges",
    meta: { title: "Accenta Education - College Programs - Smart Teacher & more" },
  },
  {
    path: "corporates",
    component: Corporates,
    name: "Corporates",
    meta: { title: "Accenta Education - Corporate Programs - Business Communication & more" },
  },
  {
    path: "careers",
    component: Careers,
    name: "Careers",
    meta: { title: "Accenta Education - Careers - Join Us" },
  },

  { path: "*", component: Redirect, meta: { title: "Home" } },
];
routes = [
  {
    path: "/",
    component: SiteLayout,
    children: siteRoutesArray,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.PUBLIC_PATH,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
