import Vue from "vue";
import App from "./App.vue";
import router from "./router/routerSite.js";
import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll, {
  duration: 1500,
  updateHistory: false,
  offset: -80,
});


router.beforeEach(async (to, from, next) => {
  document.title =  to.meta.title;
  // await document
  //   .querySelector("meta[property='og:title'")
  //   .setAttribute("content", process.env.VUE_APP_NAME + "-" + to.meta.title);
  // await document
  //   .querySelector("meta[property='og:url'")
  //   .setAttribute("content", process.env.VUE_APP_DOMAIN + to.fullPath);
  next();
});

new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
}).$mount("#app");
