<template>
    <div id="app-main">
        <keep-alive>
            <router-view :key="key" />
        </keep-alive>
    </div>
</template>

<script>
    export default {
        name: 'AppContent',
        computed: {
            // cachedViews() {
            //     return this.$store.state.tagsView.cachedViews
            // },
            key() {
                return this.$route.path
            }
        },
        created: function () {
            
        }
    }
</script>