var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"w3l-footer-29-main"},[_vm._m(0),_c('div',{staticClass:"footer-29 pt-5 pb-4"},[_c('div',{staticClass:"container pt-md-4"},[_c('div',{staticClass:"row footer-top-29"},[_vm._m(1),_c('div',{staticClass:"col-lg-2 col-md-3 col-6 footer-list-29 mt-md-0 mt-4"},[_c('ul',[_c('h6',{staticClass:"footer-title-29"},[_vm._v("Quick Links")]),_c('li',[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("About Us")])],1),_c('li',[_c('router-link',{attrs:{"to":"/ielts"}},[_vm._v("Courses")])],1),_c('li',[_c('router-link',{attrs:{"to":"/schools"}},[_vm._v("Services")])],1),_c('li',[_c('router-link',{attrs:{"to":"/contact"}},[_vm._v("Contact Us")])],1),_c('li',[_c('router-link',{attrs:{"to":"/careers"}},[_vm._v("Careers")])],1)])]),_c('div',{staticClass:"col-lg-2 col-md-3 col-6 ps-lg-5 ps-lg-4 footer-list-29 mt-md-0 mt-4"},[_c('ul',[_c('h6',{staticClass:"footer-title-29"},[_vm._v("Explore")]),_vm._m(2),_c('li',[_c('router-link',{attrs:{"to":"/privacy-policy"}},[_vm._v("Privacy policy")])],1),_c('li',[_c('router-link',{attrs:{"to":"/admissions"}},[_vm._v("Admissions")])],1),_vm._m(3),_vm._m(4)])]),_vm._m(5)]),_vm._m(6),_c('p',{staticClass:"copy-footer-29 text-center pt-lg-2 mt-5 pb-2"},[_vm._v(" © 2023 Accenta Education. All Rights Reserved. ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"float",attrs:{"href":"//api.whatsapp.com/send?phone=917034553111&text=Hello","target":"_blank"}},[_c('i',{staticClass:"bx bxl-whatsapp my-float"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-6 footer-list-29"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/site/images/logo-white.png"),"alt":""}}),_c('p',{staticClass:"mb-2 pe-xl-5 pt-3"},[_vm._v(" Accenta Education, 1 st Floor, Swarnam Arcade, Anamala Junction, N. Chalakudy, Thrissur, Kerala. ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" Phone Number : "),_c('a',{attrs:{"href":"tel:7034553111"}},[_vm._v("+91 7034553111")]),_vm._v(", "),_c('a',{attrs:{"href":"tel:7034513111"}},[_vm._v("+91 7034513111")])]),_c('p',{staticClass:"mb-2"},[_vm._v(" Email : "),_c('a',{attrs:{"href":"mailto:info@accenta.in"}},[_vm._v("info@accenta.in")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://youtube.com/@AccentaEducation","target":"_blank"}},[_vm._v("Youtube")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.facebook.com/accenta?mibextid=ZbWKwL","target":"_blank"}},[_vm._v("Latest News")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://www.accentaielts.in/","target":"_blank"}},[_vm._v("IELTS Blog")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8 footer-list-29 mt-lg-0 mt-4 ps-lg-5 d-none d-sm-block"},[_c('h6',{staticClass:"footer-title-29"},[_vm._v("Location")]),_c('iframe',{staticStyle:{"border":"10px"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15701.555546559004!2d76.3371003!3d10.3107296!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080241947d9153%3A0x1e74db030c989632!2sAccenta%20Education!5e0!3m2!1sen!2sin!4v1678467387780!5m2!1sen!2sin","width":"350","height":"250","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded-social-buttons pt-4"},[_c('a',{staticClass:"social-button facebook",attrs:{"href":"https://www.facebook.com/accenta?mibextid=ZbWKwL","target":"_blank"}},[_c('i',{staticClass:"fab fa-facebook-f"})]),_c('a',{staticClass:"social-button twitter",attrs:{"href":"https://twitter.com/accentaedu?t=xrhxmLpddJerETx_ljNbpA&s=08","target":"_blank"}},[_c('i',{staticClass:"fab fa-twitter"})]),_c('a',{staticClass:"social-button linkedin",attrs:{"href":"https://www.linkedin.com/in/accenta-education-3856b5263","target":"_blank"}},[_c('i',{staticClass:"fab fa-linkedin"})]),_c('a',{staticClass:"social-button youtube",attrs:{"href":"https://youtube.com/@AccentaEducation","target":"_blank"}},[_c('i',{staticClass:"fab fa-youtube"})]),_c('a',{staticClass:"social-button instagram",attrs:{"href":"https://instagram.com/accenta_education?igshid=ZDdkNTZiNTM=","target":"_blank"}},[_c('i',{staticClass:"fab fa-instagram"})])])
}]

export { render, staticRenderFns }